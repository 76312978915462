import React from "react"

import styles from "./ContactInfo.module.scss"

import useTranslation from "../../hooks/useTranslation"

const ContactInfo = ({ className, darkMode }) => {
  const { t } = useTranslation("footer")

  let itemStyle = styles.infoBox__item
  if (darkMode) {
    itemStyle += " " + styles.infoBox__item__dark
  }

  return (
    <div className={`${styles.infoBox} ${className}`}>
    </div>
  )
}

export default ContactInfo
