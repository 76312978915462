import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Cookies from "js-cookie"
import { toast } from "react-toastify"

import ToastBody from "../ToastBody"

import styles from "./ContactForm.module.scss"
import useTranslation from "../../hooks/useTranslation"

const ContactForm = ({ className, darkMode }) => {
  const { t } = useTranslation("mainPage")

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const { site } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          hubspotId
          contactFormId
        }
      }
    }
  `)

  const handleSubmit = e => {
    e.preventDefault()

    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null

    const portalId = site.siteMetadata.hubspotId
    const formId = site.siteMetadata.contactFormId
    const hubspotBearerToken = site.siteMetadata.hubspotBearerToken
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "message",
          value: message,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        "authorization": `Bearer ${hubspotBearerToken}`,
        "Accept": "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(r => {
        if (r.status === 200 || r.status === 201) {
          setName("")
          setEmail("")
          setMessage("")

          toast.success(<ToastBody text={t("contactUs.toast.success")} />)
        } else {
          toast.error(<ToastBody text={t("contactUs.toast.valueError")} />)
        }
      })
      .catch(err => {
        toast.error(<ToastBody text={t("contactUs.toast.sendError")} />)
      })
  }

  let btnStyle = styles.contactForm__button
  if (darkMode) {
    btnStyle += " " + styles.contactForm__button__dark
  }

  return (
    <div className={`${styles.contactForm} ${className}`}>
      <input
        className={styles.contactForm__input}
        placeholder={t("contactUs.form.name")}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <input
        className={styles.contactForm__input}
        placeholder={t("contactUs.form.email")}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <textarea
        className={styles.contactForm__input}
        rows={8}
        placeholder={t("contactUs.form.msg")}
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <button className={btnStyle} onClick={handleSubmit}>
        {t("contactUs.form.submit")}
      </button>
    </div>
  )
}

export default ContactForm
