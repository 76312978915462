import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

import styles from "./index.module.scss"

import vector from "../../assets/images/marker.png"

const GoogleMapsCtrl = props => {
  const mapStyles = {
    width: "100%",
    height: "371px",
  }

  return (
    <div className={styles["map"]}>
      <Map
        google={props.google}
        zoom={16}
        style={mapStyles}
        initialCenter={{ lat: 59.346908, lng: 18.063154 }}
      >
        <Marker
          position={{ lat: 59.346908, lng: 18.063154 }}
          icon={{
            url: vector,
            anchor: new window.google.maps.Point(5, 58),
          }}
        />
      </Map>
    </div>
  )
};

const GoogleMaps = (props) => {
    const {site} = useStaticQuery(graphql`
        query GoogleMapsMetadata {
            site {
                siteMetadata {
                    googleMapsKey
                }
            }
        }
    `);

    const [mapsHOC, setMapsHOC] = useState({ctrl: undefined});
    useEffect(() => {
        setMapsHOC({
            ctrl: GoogleApiWrapper({apiKey: site.siteMetadata.googleMapsKey})(GoogleMapsCtrl)
        });
    }, [site]);

    return mapsHOC.ctrl ? <mapsHOC.ctrl {...props} /> : <div />;
};

export default GoogleMaps;
