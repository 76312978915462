import React, { useState } from "react"
import Slider from "react-slick"

import "./slider.scss"
import styles from "./ClientSlider.module.scss"

const ClientSlider = ({ className, items }) => {
  const [currSlide, setCurrSlide] = useState(0)

  const itemCount = items ? items.length : 1
  let opaqueStart = (currSlide + 1) % itemCount
  let opaqueEnd = (currSlide + 3) % itemCount

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    beforeChange: (_, next) => {
      setCurrSlide(next)
    },
  }

  return (
    <div className={className}>
      <Slider {...settings}>
        {items &&
          items.map((i, index) => {
            const isOpaque =
              opaqueStart <= opaqueEnd
                ? index >= opaqueStart && index <= opaqueEnd
                : index >= opaqueStart || index <= opaqueEnd
            const itemStyle = isOpaque
              ? `${styles.clientSlider__slide} ${styles.clientSlider__slide__opaque}`
              : styles.clientSlider__slide

            return (
              <div key={`ClientSliderSlide_${index}`} className={itemStyle}>
                <div className={styles.clientSlider__slide__inner}>
                  <img src={i} alt="" />
                </div>
              </div>
            )
          })}
      </Slider>
    </div>
  )
}

export default ClientSlider
